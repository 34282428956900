import Vue from 'vue';
import Component from 'vue-class-component';
import AppNavDrawer from '@/core/app-nav-drawer/app-nav-drawer.vue';
import AppNavbar from '@/core/app-navbar/app-navbar.vue';
import AppFooter from '@/core/app-footer/app-footer.vue';
import Snackbar from '@/shared/components/snackbar/snackbar.vue';
import PromptDialog from '@/shared/components/prompt-dialog/prompt-dialog.vue';
import { Inject, Watch } from 'vue-property-decorator';
import { threadId } from 'worker_threads';
import TranslationService from '@/locale/translation.service';

@Component({
  components: { AppNavDrawer, AppNavbar, AppFooter, Snackbar, PromptDialog },
})
export default class App extends Vue {
  @Inject('translationService') private translationService: () => TranslationService;

  private currentLanguage = this.$store.getters.currentLanguage;
  private showDrawer = false;

  @Watch('$route')
  public onRouteChanged(val: boolean): void {
    this.closeDrawer();
  }

  private get showNavDrawer() {
    return this.$route.name !== 'Login' && this.$route.name !== 'ResetPasswordInit' && this.$route.name !== 'ResetPasswordFinish';
  }

  private toggleDrawer() {
    this.showDrawer = !this.showDrawer;
  }

  private closeDrawer() {
    this.showDrawer = false;
  }

  created() {
    this.translationService().refreshTranslation(this.currentLanguage);
  }
}
