import axios from 'axios';
import { IOrder } from '@/shared/model/order.model';
import { IPTVExportQuery } from '@/export/ptv-export-query.model';
import { IOrderList } from '@/shared/model/order-list.model';

const baseApiUrl = 'api';

export default class PtvExportService {
  public findOrders(ptvExport: IPTVExportQuery): Promise<IOrderList[]> {
    return new Promise<IOrderList[]>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/query-ptv-data`, ptvExport)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public exportPtvData(orders: IOrder[]) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/export-ptv-data`, orders, {
          responseType: 'blob',
        })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
          link.href = url;
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public sendDeliveryMails(orders: number[]) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/send-delivery-mails`, orders)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public sendOrderCancellationMails(orders: number[], reason: number) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${baseApiUrl}/send-order-cancellation-mails`,
          { orderIds: orders, cancellationReasonId: reason },
          {
            headers: { 'Content-type': 'application/json' },
          }
        )
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public sendOrderRegistrationMails(orders: number[]) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/send-order-registration-mails`, orders)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public showDirections(addresses) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/show-directions`, addresses)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public calculateAveragePrice() {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/calculate-average-price`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
