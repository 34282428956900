import Vuex from 'vuex';
import VueI18n, {DateTimeFormats} from 'vue-i18n';
import JhiFormatter from './formatter';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import de from 'vuetify/src/locale/de';

import VueCookie from 'vue-cookie';
import Vuelidate from 'vuelidate';
import Vue2Filters from 'vue2-filters';

import * as filters from '@/shared/date/filters';
import {accountStore} from '@/shared/config/store/account-store';
import {translationStore} from '@/shared/config/store/translation-store';
import {appStore} from '@/shared/config/store/app-store/';
import {setupAxiosInterceptors} from '@/shared/config/axios-interceptor';

const dateTimeFormats: DateTimeFormats = {
  de: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  // jhipster-needle-i18n-language-date-time-format - JHipster will add/remove format options in this object
};

export function initVueApp(vue) {
  vue.use(VueCookie);
  vue.use(Vuelidate);
  vue.use(Vue2Filters);
  vue.use(Vuetify);

  setupAxiosInterceptors(
    error => {
      const url = error.response?.config?.url;

      const status = error.status || error.response.status;
      if (status === 401) {
        // Store logged out state.
        //store.commit('logout');
        if (!url.endsWith('api/account') && !url.endsWith('api/authenticate')) {
          // Ask for a new authentication
          //loginService.openLogin(vue);
          return;
        }
      }
      console.log('Unauthorized!');
      return Promise.reject(error);
    },
    error => {
      console.log('Server error!');
      return Promise.reject(error);
    }
  );

  filters.initFilters();
}

const messages = {
  de: {
    $vuetify: {
      dataIterator: {
        rowsPerPageText: 'Items per page:',
        pageText: '{0}-{1} of {2}',
      },
    },
  },
};

export function initI18N(vue) {
  vue.use(VueI18n);
  return new VueI18n({
    dateTimeFormats,
    silentTranslationWarn: true,
    formatter: new JhiFormatter(),
    locale: 'de',
  });
}

export function initVueXStore(vue) {
  vue.use(Vuex);
  return new Vuex.Store({
    modules: {
      accountStore,
      translationStore,
      appStore,
    },
  });
}

export function initVuetify(vue) {
  vue.use(Vuetify);
  const opts = {
    theme: {
      dark: true,
      themes: {
        light: {
          primary: '#D72323',
          accent: '#D72323',
        },
        dark: {
          primary: '#e41432',
          accent: '#e41432',
          success: '#1C8D73'
        },
      },
    },
    lang: {
      locales: { de },
      current: 'de',
    },
  };
  return new Vuetify(opts);
}
