import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

// prettier-ignore
const PtvExport = () => import('@/export/ptv-export.vue');
// prettier-ignore
const CustomerCmp = () => import('@/customer/customerCmp.vue');
// prettier-ignore
const CustomerUpdate = () => import('@/customer/customer-update.vue');
// prettier-ignore
const OrderCmp = () => import('@/order/order-cmp.vue');
// prettier-ignore
const OrderUpdate = () => import('@/order/order-update.vue');
// prettier-ignore
const OrderBilling = () => import('@/order/order-billing-dialog.vue');
// prettier-ignore
const OrderCalendar = () => import('@/order-calendar/order-calendar.vue');
// prettier-ignore
const BillingsJournal = () => import('@/billings-journal/billings-journal.vue');
// prettier-ignore
const DeletedOrders = () => import('@/deleted-orders/deleted-orders.vue');
// prettier-ignore
const Activities = () => import('@/activities/activities.vue');
// prettier-ignore
//const DeliveryBilling = () => import('@/delivery-billing/delivery-cmp.vue');
// prettier-ignore
//const DeliveryUpdate = () => import('@/delivery-billing/delivery-update.vue');
// prettier-ignore
const Stock = () => import('@/stock/stock.vue');
// prettier-ignore
const StockUpdate = () => import('@/stock/stock-update.vue');
// prettier-ignore
const BillingOverview = () => import('@/billing-overview/billing-overview.vue')

export default [
  // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  {
    path: '/ptv-export',
    name: 'PtvExport',
    component: PtvExport,
    props: true,
    meta: { authorities: [Authority.ADMIN, Authority.USER] },
  },
  {
    path: '/customer',
    name: 'Customer',
    component: CustomerCmp,
    meta: { authorities: [Authority.ADMIN, Authority.USER] },
  },
  {
    path: '/customer/new',
    name: 'CustomerCreate',
    component: CustomerUpdate,
    meta: { authorities: [Authority.ADMIN, Authority.USER] },
  },
  {
    path: '/customer/:customerId/edit',
    name: 'CustomerEdit',
    component: CustomerUpdate,
    meta: { authorities: [Authority.ADMIN, Authority.USER] },
  },
  {
    path: '/order/:customerId',
    name: 'Order',
    component: OrderCmp,
    meta: { authorities: [Authority.ADMIN, Authority.USER] },
  },
  {
    path: '/order/new/:customerId',
    name: 'OrderCreate',
    component: OrderUpdate,
    meta: { authorities: [Authority.ADMIN, Authority.USER] },
  },
  {
    path: '/order/:orderId/edit',
    name: 'OrderEdit',
    component: OrderUpdate,
    meta: { authorities: [Authority.ADMIN, Authority.USER] },
  },
  {
    path: '/order/:orderId/billing',
    name: 'OrderBilling',
    component: OrderBilling,
    meta: { authorities: [Authority.ADMIN, Authority.USER] },
  },
  {
    path: '/order-calendar',
    name: 'OrderCalendar',
    component: OrderCalendar,
    meta: { authorities: [Authority.ADMIN, Authority.USER] },
  },
  {
    path: '/billings-journal',
    name: 'BillingsJournal',
    component: BillingsJournal,
    meta: { authorities: [Authority.ADMIN, Authority.USER] },
  },
  {
    path: '/deleted-orders',
    name: 'DeletedOrders',
    component: DeletedOrders,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/management/deleted-orders',
    name: 'ManagementDeletedOrders',
    component: DeletedOrders,
    meta: { authorities: [Authority.MANAGER] },
  },
  {
    path: '/management/activities',
    name: 'ManagementActivities',
    component: Activities,
    meta: { authorities: [Authority.MANAGER] },
  },
  /*
  {
    path: '/billing-overview',
    name: 'BillingOverview',
    component: BillingOverview,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/stock',
    name: 'Stock',
    component: Stock,
    meta: { authorities: [Authority.ADMIN, Authority.USER] },
  },
   */
];
